import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { layoutStyles } from '../style/layoutStyles';
import PageContent from '../components/pageContent'

export default ({ data }) => {
  const post = data.wpgraphql.harbour
  var keywords = [];
  if (post.keywords.nodes != null)
  {
    post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }
  return (
    <Layout>
        <SEO title={post.title} keywords={keywords} />
        <div css={layoutStyles} id="content">
        <div className="grid">
            <div id="sectionPage" className="col-1-1">
              <div id="article" className="col-12-12">
                <h1 dangerouslySetInnerHTML={{ __html: post.title }}></h1>
                <PageContent content={post.content} />
              </div>
          </div>
        </div>
        <div className="whitespacer"></div>
      </div>

    </Layout>
  )
}
export const query = graphql`
query GET_HARBOURFULL($id: ID!) {
    wpgraphql {
      harbour(id: $id) {
        title
        content(format: RENDERED)
        slug
        keywords {
          nodes {
            name
          }
        }
    }
  }
}
`